export enum Scenes {
	OptIn = 'optIn'
}

export const scenes = {
	[Scenes.OptIn]: {
		pattern: '/:code',
		url: '/:code',
	}
}
