import { createGlobalStyle } from 'styled-components'
import 'antd/dist/antd.less'
import { atMobile, atTablet } from 'utils/styled'

export const GlobalStyles = createGlobalStyle`
    body {
        font-size: 16px;
        ${atMobile(`
            font-size: 14px;
        `)}

        ${atTablet(`
            font-size: 15px;
        `)}
    }
    
    body > #root {
        height: 100vh;
    }

    button {
        border: none;
    }
`
