// Types
import { OptinInfo } from './optin.types'
import { API_URL } from '@constants'

const URL_OPTIN = (contact_code: string) => `${API_URL}/optin/${contact_code}`

class OptinService {
	getOptinInfo = async (contact_code: string): Promise<OptinInfo> => {
		const url = URL_OPTIN(contact_code)

		return await fetch(url, {
			method: 'GET',
		})
			.then(res => res.json())
			.then(res => {
				if (res && res.errors) {
					throw new Error(res.errors)
				}
				return res
			})
	}

	sentForm = async (
		contact_code: string,
		first_name: string,
		last_name: string,
		email?: string
	): Promise<OptinInfo> => {
		const url = URL_OPTIN(contact_code)
		const formData = new FormData()
		formData.append('first_name', first_name)
		formData.append('last_name', last_name)

		if (email) {
			formData.append('email', email)
		}

		return await fetch(url, {
			method: 'POST',
			body: formData,
		})
			.then(res => res.json())
			.then(res => {
				if (res && res.errors) {
					throw new Error(res.errors)
				}

				return res
			})
	}
}

export default new OptinService()
