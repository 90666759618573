import React, { lazy } from 'react'
import { Route } from 'react-router'
import { BrowserRouter } from 'react-router-dom'
import { scenes } from 'scenes/scenes'

const OptIn = lazy(() => import('scenes/Optin'))

const AppScenes = () => {
	return (
		<BrowserRouter>
			<Route exact path={scenes.optIn.pattern} component={OptIn} />
			<Route exact path='/' component={OptIn} />
		</BrowserRouter>
	)
}

export default AppScenes
