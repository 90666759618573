import { BASE_URL, ENVIRONMENT } from "@constants/envs";

export const isProductionBuild = ENVIRONMENT === "production";

export const CACHE_VERSION = "1";
export const PERSIST_KEY = `conversations-${ENVIRONMENT}`;
export const PERSIST_TIMEOUT = 9000000000000000;
export const PERSIST_STORAGE_KEY = `${CACHE_VERSION}${PERSIST_KEY}`;

export const API_URL = BASE_URL;
