import {
	configureStore,
	ThunkAction,
	Action,
	combineReducers,
	getDefaultMiddleware,
	ThunkDispatch,
} from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createLogger } from 'redux-logger'
import {
	isProductionBuild,
	PERSIST_TIMEOUT,
	CACHE_VERSION,
	PERSIST_KEY,
} from '@constants'
import optin from 'store/slices/optin'


import { useDispatch, useSelector } from 'react-redux'

export type RootState = ReturnType<typeof rootReducer>

const appReducer = combineReducers({
	optin,
})

const rootReducer = (state: any, action: any) => {
	return appReducer(state, action)
}

export const mainPersistConfig = {
	storage,
	key: PERSIST_KEY,
	timeout: PERSIST_TIMEOUT,
	keyPrefix: CACHE_VERSION,
	whitelist: ['auth'],
}

const persistedReducer = persistReducer(mainPersistConfig, rootReducer)


const middleware = [
	...getDefaultMiddleware({
		immutableCheck: false,
		serializableCheck: false,
	}),
]

if (!isProductionBuild) {
	const logger = createLogger()
	middleware.push(logger)
}

export const store = configureStore({
	reducer: persistedReducer,
	devTools: !isProductionBuild,
	middleware,
})

export const persistor = persistStore(store)

export type IStoreState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	IStoreState,
	unknown,
	Action<string>
>

export type AppDispatch = ThunkDispatch<IStoreState, unknown, Action<string>>
export const useAppState = <Selected = unknown>(
	selector: (state: RootState) => Selected,
	equalityFn?: (left: Selected, right: Selected) => boolean
) => useSelector(selector, equalityFn)
export const useAppDispatch = () => useDispatch<AppDispatch>()
