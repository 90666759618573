import { createSlice } from '@reduxjs/toolkit'
import createAsyncReducers from 'utils/create-async-reducers'
import { getOptinInfo } from './optin.actions'
import { OptinInfo } from 'services/optin/optin.types'

export interface IState {
	optinInfo: OptinInfo
	loadingInfo: boolean
}

const initialState: IState = {
	optinInfo: {} as OptinInfo,
	loadingInfo: false,
}

const { reducer, actions } = createSlice({
	name: 'optin',
	initialState,
	reducers: {},
	extraReducers: builder => {
		createAsyncReducers(
			builder,
			getOptinInfo,
			'loadingInfo',
			(state, actions) => {
				state.optinInfo = actions.payload
			}
		)
	},
})

export default reducer

export const optinActions = {
	...actions,
	getOptinInfo,
}
