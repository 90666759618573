import { css, ThemedStyledProps } from 'styled-components'
import { IBaseTheme } from 'modules/Theme/styled'
import { CSSProperties } from 'react'

export const breakpoints = {
	mobile: 475,
	tablet: 768,
	tabletLarge: 960,
	desktop: 1024,
	iPadPro: 1223,
	desktopLarge: 1650,
}

export const devices = {
	desktopOrLaptop: { query: '(min-device-width: 1224px)' },
	bigScreen: { query: '(min-device-width: 1824px)' },
	tabletOrMobile: { query: '(max-width: 1224px)' },
	tabletOrMobileDevice: { query: '(max-device-width: 1224px)' },
	portrait: { query: '(orientation: portrait)' },
	retina: { query: '(min-resolution: 2dppx)' },
}

export const atSmallMobile = (code: string) => css`
	@media screen and (max-width: ${breakpoints.mobile - 1}px) {
		${code};
	}
`

export const atMobile = (code: string) => css`
	@media screen and (max-width: ${breakpoints.tablet - 1}px) {
		${code};
	}
`

export const atTablet = (code: string) => css`
	@media screen and (max-width: ${breakpoints.desktop - 1}px) {
		${code};
	}
`

export const atTabletDesktopFirst = (code: string) => css`
	@media screen and (max-width: ${breakpoints.tabletLarge}px) {
		${code};
	}
`
export const atTabletLarge = (code: string) => css`
	@media screen and (min-width: ${breakpoints.tabletLarge}px) {
		${code};
	}
`

export const atDesktop = (code: string) => css`
	@media screen and (max-width: ${breakpoints.desktopLarge - 1}px) {
		${code};
	}
`

export const atIpadPro = (code: string) => css`
	@media screen and (max-width: ${breakpoints.iPadPro}px) {
		${code};
	}
`

export const atLargeDesktop = (code: string) => css`
	@media screen and (min-width: ${breakpoints.desktopLarge}px) {
		${code};
	}
`

export type ThemeWithProps<P = {}> = ThemedStyledProps<P, IBaseTheme>

export const mainFont = ({ theme }: ThemeWithProps) => `
	font-family: ${theme.fonts.main};
`

export type ClassNameAndStyle<T = unknown> = {
	className?: string
	style?: CSSProperties
} & T
