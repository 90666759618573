const {
	REACT_APP_BASE_URL,
	REACT_APP_BASE_API_VERSION,
	REACT_APP_ENVIRONMENT,
} = process.env

export {
	REACT_APP_BASE_URL as BASE_URL,
	REACT_APP_BASE_API_VERSION as BASE_API_VERSION,
	REACT_APP_ENVIRONMENT as ENVIRONMENT,
}