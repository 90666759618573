import React, { Suspense } from 'react'
import { Provider as StoreProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import Scenes from 'scenes'
import Theme from 'modules/Theme'
import { store, persistor } from 'store'
import { GlobalStyles } from './styles'

export default function App() {
	return (
		<Theme>
				<StoreProvider store={store}>
					<PersistGate loading={null} persistor={persistor}>
						<Suspense fallback={null}>
							<GlobalStyles />
							<Scenes />
						</Suspense>
					</PersistGate>
				</StoreProvider>
		</Theme>
	)
}
