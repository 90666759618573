import merge from 'lodash/merge'
import { THEME } from './enums'

const COLORS = [
	'white',
	'fontMain',
	'fontSecondary',
	'fontTertiary',
	'border',
	'accentPrimary',
	'accentText',
	'accentBackground',
	'backgroundMain',
	'icon',
	'focus',
	'success',
	'error',
	'errorPrimary',
	'errorBackground',
	'warning',
	'warningPrimary',
	'warningBackground',
	'disabled',
	'danger',
	'dangerHover',
	'dangerClick',
] as const
export type Colors = typeof COLORS[number]
export function isColor(key: string): key is Colors {
	return COLORS.includes(key as Colors)
}

const colors: Record<Colors, string> = {
	white: 'white',
	fontMain: '#231f20',
	fontSecondary: '#9c9a9b',
	fontTertiary: '#bdbbbc',
	border: '#f0eff0',
	accentPrimary: '#00A79D',
	accentText: '#00A79D',
	accentBackground: '#F4FAFA',
	backgroundMain: '#F8F8F8',
	icon: '#BDBBBC',
	focus: '#00A79D',
	success: '#8EC53D',
	error: '#d80027',
	errorPrimary: '#ef3b39',
	errorBackground: '#fae4e4',
	warning: '#ffcb00',
	warningPrimary: '#ffcf33',
	warningBackground: '#fff0c1',
	disabled: '#f0eff0',
	danger: '#FFDFDF',
	dangerHover: '#EF3B39',
	dangerClick: '#CF2C2B',
}

//Type and Const List for Fonts
export const FONTS = [
	'secondary',
	'main',
	'calpsLightFamily',
	'calpsBold',
	'zoojaRegular',
	'Calps',
] as const
export type Font = typeof FONTS[number]

//Fonts for Text Properties
export const fontsMapped: Record<Font, string> = {
	secondary: 'Source Sans Pro',
	main: 'Source Sans Pro',
	calpsLightFamily: 'calpslight',
	calpsBold: 'calpsbold',
	zoojaRegular: 'zoojaregular',
	Calps: 'Calps',
}

export const fonts = {
	main: 'Source Sans Pro',
	rounded: 'SF Pro Rounded',
	calpsBold: 'calpsbold',
	calpsLightFamily: 'calpslight',
}

export const baseTheme = {
	colors,
	fonts,
}

const lightTheme = { ...baseTheme }

const darkTheme = merge({}, baseTheme, {
	// TODO: implement dark theme
})

export const themes = {
	[THEME.Light]: lightTheme,
	[THEME.Dark]: darkTheme,
}

export interface IBaseTheme extends Readonly<typeof baseTheme> {}

declare module 'styled-components' {
	export interface DefaultTheme extends IBaseTheme {}
}
